<script>
  export let infobox;
  let engines = JSON.parse(infobox.engines);
  let links = JSON.parse(infobox.urls);
  let attributes = JSON.parse(infobox.attributes);
  console.log(attributes);
</script>

<div class="col-sm-4 col-sm-push-8" id="sidebar_results">
  <div class="infobox">
    <div class="infobox-heading">
      <h4 class="infobox-title"><bdi>{infobox.infobox}</bdi></h4>
    </div>
    <div class="infobox-body">
      <img class="infobox-img" src={infobox.img_src} alt={infobox.infobox} />
      <bdi
        ><div class="infobox-content">
          {infobox.content}
        </div></bdi
      >
    </div>
    <div class="infobox-attributes">
      <table class="table">
        <tbody>
          {#each attributes as attribute}
            <tr
              ><td><bdi>{attribute.label}</bdi></td>
              <td><bdi>{attribute.value}</bdi></td></tr
            >
          {/each}
        </tbody>
      </table>
    </div>
    <div class="infobox-links">
      {#each links as url}
        <a class="infobox-link" href={url.url} rel="noreferrer">{url.title}</a>
      {/each}
    </div>
  </div>
</div>

<style>
  .infobox {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    /* align-items: center; */
    background-color: rgb(255, 255, 255);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: rgba(12, 12, 14, 0.2) 0px 1px 4px 0px,
      rgba(12, 12, 14, 0.12) 0px 0px 2px 0px;

    box-sizing: border-box;
    font-stretch: 100%;
    font-style: normal;
    font-weight: 400;
    /* line-height: 24px; */
    max-width: 452px;
    min-width: 0px;
    text-rendering: optimizespeed;
    transition-delay: 0s, 0s;
    transition-duration: 0.3s, 0.3s;
    transition-property: box-shadow, background;
    transition-timing-function: ease, ease;
    margin-bottom: 10px;
  }
  .infobox-body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .infobox-heading {
    padding-left: 16px;
  }
  .infobox-content {
    /* margin: 1rem; */
    padding: 16px;
    font-size: 14px;
    line-height: 22px;
    font-style: italic;
  }

  .infobox-title {
    font-size: 20px;
    line-height: 1.2;
  }

  .infobox-img {
    max-width: 200px;
  }
  .infobox-attributes {
    padding: 16px;
    font-size: 14px;
  }
  .infobox-links {
    padding: 16px;
    font-size: 14px;
    justify-content: left;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .infobox-link {
    text-transform: capitalize;
    margin-right: 10px;
    background-color: #007ab8;
    border: none;
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    color: white;
  }

  @media (max-width: 640px) {
  }
</style>
