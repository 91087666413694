<script>
  export let result;
  let url = result.url;
  let engines;
  if (typeof result.engines == "string") {
    engines = result.engines.split(",");
  } else {
    engines = result.engines;
  }
  url = url.replace(/^https?:\/\//, "");
  // url = url.replace(http://", "");
  url = url.replace("www.", "");
  // url = url.replace("/", " > ");
  url = url.replace(/\/$/, "");
  // url = url.slice(0, -1);
</script>

<article class="result" data-vim-selected="true">
  <div class="heading">
    <a href={result.url} target="_blank" rel="noopener noreferrer"
      >{result.title}</a
    >
  </div>
  <div class="url">
    {url}
  </div>
  {#if result.content}
    <p class="content">
      {@html result.content}
    </p>
  {/if}
  <!-- {#if result.img_url} -->

  <!-- {/if} -->

  <div class="engines">
    {#each engines as engine}
      <span class="engine">{engine}</span>
    {/each}
  </div>
  {#if result.category}
    <div class="categories">
      <span class="category">{result.category}</span>
    </div>
  {/if}

  <div class="break" />
</article>

<style>
  .result {
    /* margin: 1rem; */
    border-radius: 25px;
  }
  .heading {
    /* margin: 0.5rem; */
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
  }

  .content {
    /* margin-left: 0.5rem; */
    width: 570px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
    margin-top: 4px;
  }

  .url {
    margin: 0;
    /* margin-left: 0.5rem; */
    word-break: break-all;
    white-space: nowrap;
    max-width: 480px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    font-size: 14px;
    color: #639c3d;
    line-height: 1.2;
  }
  .engines {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    height: 10px;
    align-items: center;
  }
  .engine {
    margin-right: 10px;
    background-color: #007ab8;
    border: none;
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    color: white;
  }

  .categories {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    height: 10px;
    align-items: center;
  }
  .category {
    margin-right: 10px;
    background-color: #6500b8;
    border: none;
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    color: white;
  }

  @media (max-width: 640px) {
    .result {
      /* height: 195px; */
    }
    .url {
      width: 19.5rem;
      height: 1rem;
    }
    .content {
      margin-bottom: 16px;
      width: 300px;
    }
  }
</style>
