<script>
  import { SearchIcon, XIcon, CircleIcon } from "svelte-feather-icons";
  import { search, autocomplete, autocompleted } from "../../fetchData";
  export let query;
  var currentFocus = 0;
  $autocompleted = undefined;

  function setQuery(q) {
    query = q;
  }

  function _search(event) {
    if (event.key == "Enter") {
      if (query) {
        window.location.assign(`/#/search?query=${query}`);
        search(query);
        $autocompleted = undefined;
      }
    } else if (event.key == "ArrowUp") {
      // UP
      if (!(currentFocus === 0)) currentFocus--;
      document.querySelectorAll("#autocomplete-item li")[currentFocus].click();
    } else if (event.key == "ArrowDown") {
      //DOWN
      let x = document.querySelectorAll("#autocomplete-item li");
      x[currentFocus].click();
      if (currentFocus < x.length - 1) currentFocus++;
      let v = x[currentFocus].getAttribute("class");
      x[currentFocus].setAttribute("class", `${v} active`);
    } else {
      if (query) autocomplete(query);
    }
  }

  function clear() {
    query = undefined;
    currentFocus = 0;
    $autocompleted = undefined;
  }
</script>

<div class="logo">
  <a href="/#/"><CircleIcon size="2x" /> </a>
</div>
<div class="search-wrapper">
  <div class="search-box">
    <div class="search-holder">
      <div class="search-input">
        <input
          id="query"
          class="query"
          type="text"
          placeholder="Search for..."
          autocomplete="off"
          spellcheck="false"
          dir="auto"
          bind:value={query}
          on:keydown={_search}
        />
      </div>
      <button
        class="search-button search-clear"
        type="button"
        on:click={clear}
        class:hidden={!query}
        ><XIcon size="1x" />
      </button>
      <button
        class="search-button search-send"
        type="submit"
        on:keypress={_search}
        on:click={search(query)}
      >
        <SearchIcon size="1x" />
      </button>
    </div>

    <div class="autocomplete" class:open={$autocompleted}>
      <ul id="autocomplete-item">
        {#if $autocompleted}
          {#each $autocompleted as ac}
            <li on:click={setQuery(ac)}>{ac}</li>
          {/each}
        {/if}
      </ul>
    </div>
  </div>
</div>

<!-- <div class="search-filters">
   <select class="language" id="language" name="language"
    ><option value="all" selected="selected">Default language</option></select
  > 
  <select name="time_range" id="time_range" class="time_range" tabindex="3"
    ><option id="time-range-anytime" value="" selected="">Anytime</option
    ><option id="time-range-day" value="day">Last day</option><option
      id="time-range-week"
      value="week">Last week</option
    ><option id="time-range-month" value="month">Last month</option><option
      id="time-range-year"
      value="year">Last year</option
    ></select
  >
</div> -->

<!-- <div class="search-categories">
  <div class="categories-container">
    <div class="category">
      <input type="checkbox" name="category_general" checked="checked" />
      <label for="checkbox_general" class="tooltips">
        <AlignJustifyIcon size="1x" />
        <div class="category_name">general</div>
      </label>
    </div>
    <div class="category">
      <input type="checkbox" name="category_images" />
      <label for="checkbox_images" class="tooltips">
        <ImageIcon size="1x" />
        <div class="category_name">images</div>
      </label>
    </div>
  </div>
</div>
<input type="hidden" name="safesearch" value="0" />
<input type="hidden" name="theme" value="simple" /> -->
<style>
  .logo {
    margin: 1rem;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .search-wrapper {
    display: flex;
  }
  .search-box {
    display: flex;
    align-items: center;
    /* width: 40rem; */
  }

  .search-holder {
    display: flex;
    background-color: rgb(245, 245, 247);
    border-radius: 34px;
    transition: 0.3s;
  }

  .search-holder:hover {
    /* background-color: white; */
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .search-input {
    /* background-color: rgb(245, 245, 247); */
    border-radius: 34px;
    transition: 0.3s;
  }
  .search-input:focus {
    background-color: white;
    transition: 0.3s;
  }

  .query {
    width: 20rem;
    font-size: 16px;
    color: inherit;
    padding-left: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
    outline: none;
    background-color: transparent;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .search-send {
    margin-right: 10px;
  }

  .search-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    align-items: center;
    display: inline-flex;
  }

  .autocomplete {
    position: absolute;
    max-height: 0;
    overflow-y: hidden;
    text-align: left;
    width: 478px;
    top: 70px;
    border-top: transparent;
    left: 136px;
    border-width: 1px;
    border-color: lightgray;
  }
  .autocomplete:active,
  .autocomplete:focus,
  .autocomplete:hover {
    background-color: white;
  }
  .autocomplete:empty {
    display: none;
  }
  .autocomplete > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .autocomplete > ul > li {
    cursor: pointer;
    padding: 8px 0 8px 8px;
  }
  .autocomplete > ul > li.active,
  .autocomplete > ul > li:active,
  .autocomplete > ul > li:focus,
  .autocomplete > ul > li:hover {
    background-color: gray;
  }
  .autocomplete > ul > li.active a:active,
  .autocomplete > ul > li.active a:focus,
  .autocomplete > ul > li.active a:hover,
  .autocomplete > ul > li:active a:active,
  .autocomplete > ul > li:active a:focus,
  .autocomplete > ul > li:active a:hover,
  .autocomplete > ul > li:focus a:active,
  .autocomplete > ul > li:focus a:focus,
  .autocomplete > ul > li:focus a:hover,
  .autocomplete > ul > li:hover a:active,
  .autocomplete > ul > li:hover a:focus,
  .autocomplete > ul > li:hover a:hover {
    text-decoration: none;
  }
  .autocomplete > ul > li.locked {
    cursor: inherit;
  }
  .autocomplete.open {
    display: block;
    background-color: white;
    color: black;
    border: 1px solid lightgray;
    max-height: 500px;
    overflow-y: auto;
    z-index: 100;
  }
  .autocomplete.open:empty {
    display: none;
  }
  /* @media screen and (max-width: 640px) {
    .autocomplete {
      bottom: 0;
    }
    .autocomplete > ul > li {
      border-bottom: 1px solid lightgray;
      text-align: left;
    }
  } */

  .hidden {
    visibility: hidden;
  }
  @media (max-width: 640px) {
    .search-box {
      justify-content: center;
    }
    .search-box {
      /* width: 0; */
      /* max-width: 17rem; */
    }
    .query {
      max-width: 16rem;
    }

    .autocomplete {
      /* width: 190px; */
      max-width: 16rem;
      left: 88px;
      max-height: 200px;
    }
    .autocomplete .open {
      max-height: 200px;
    }
  }

  @media (min-width: 640px) {
    .query {
      width: 30rem;
    }
  }
</style>
