<script lang="ts">
  import Router from "./router.svelte";
</script>

<main class="main">
  <Router />
</main>

<style>
  .main {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
</style>
