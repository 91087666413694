<script>
  import Router from "svelte-spa-router";
  import Footer from "./components/blocks/footer.svelte";
  import Home from "./components/home.svelte";
  import Search from "./components/search.svelte";
</script>

<Router
  routes={{
    "/": Home,
    "/search": Search,
    "/search?query=:query": Search,
  }}
/>
<Footer />
