<footer>
  <p>
    A simple privacy-respecting, hackable metasearch engine<br /> <a href="https://amansubhan.com">amansubhan</a> 2021
  </p>
</footer>

<style>
  footer {
    clear: both;
    /* position: relative; */
    /* height: 60px; */
    /* margin-top: 0px; */

    display: flex;
    flex: 0;
    text-align: center;
    justify-content: center;
    width: 100%;
    font-size: 0.8em;
  }
</style>
