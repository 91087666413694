import { writable } from "svelte/store";
export const quick = writable(undefined)
export const infobox = writable(undefined)
export const results = writable(undefined)
export const images = writable(undefined)
export const autocompleted = writable(undefined)
export const status = writable('INIT')

// const serverUrl = "http://localhost:8087"
const serverUrl = "https://api.anostic.com/v1"

export async function search(query: string) {
    getResults(query)
    await getQuick(query)
}

export function autocomplete(query: string) {
    autocompleted.set(undefined)
    _getAutoComplete(query).then(response => response.json())
        .then(data => {
            autocompleted.set(data[1])
        });
}

function getResults(query: string) {
    results.set(undefined)
    status.set('LOADING')
    _getResults(query).then(response => response.json())
        .then(data => {

            results.set(data)
            status.set('DONE')
        });
}

async function getQuick(query: string) {
    quick.set(undefined)
    infobox.set(undefined)
    images.set(undefined)
    _getQuick(query).then(response => response.json())
        .then(async (data) => {

            // if (data.results.length > 0 || data.images.length > 0) {
            quick.set(data.results);
            images.set(data.images);
            (data.infobox.length > 0) ? infobox.set(data.infobox) : infobox.set(undefined);
            // }
            // else {
            //     await delay(500)
            //     getQuick(query)
            // }
        });

}


function _getResults(query: string) {
    return fetch(`${serverUrl}/search?query=${query}`)
}

function _getQuick(query: string) {
    return fetch(`${serverUrl}/quick?query=${query}`)
}

function _getAutoComplete(query: string) {
    return fetch(`${serverUrl}/autocomplete?query=${query}`)
}

function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}