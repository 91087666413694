<script>
  import { SearchIcon } from "svelte-feather-icons";
  let searchQuery;
  function keySearch(event) {
    if (event.keyCode == 13) {
      if (searchQuery) window.location.assign(`/#/search?query=${searchQuery}`);
    }
  }
  function mSearch() {
    if (searchQuery) window.location.assign(`/#/search?query=${searchQuery}`);
  }
</script>

<div class="home">
  <h1 class="header">Hello Search!</h1>
  <p />
  <div class="search-box">
    <input
      type="text"
      class="search"
      name="query"
      placeholder="Search"
      spellcheck="false"
      autofocus
      dir="auto"
      bind:value={searchQuery}
      on:keypress={keySearch}
    />
    <button
      class="submit-search"
      tabindex="-1"
      href="/#/search"
      on:click={mSearch}
      ><SearchIcon size="1x" />
    </button>
  </div>
</div>

<style>
  .home {
    /* position: fixed; */
    /* top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .header {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
    padding: 0em;
    margin: 0em;
  }
  .search-box {
    margin: 0 12px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* white-space: nowrap; */
    max-width: 600px;
    width: 100%;
  }

  .search {
    width: 100%;
    height: 3rem;
    border-radius: 15px;
    font-size: 1em;
    padding-left: 15px;
  }

  .submit-search {
    background-color: transparent;
    border: 0;
  }

  @media (max-width: 640px) {
    .home {
      max-width: 480px;
      margin: 0 auto;
    }

    .header {
      font-size: 3em;
    }
  }
</style>
