<script>
  import Header from "./blocks/header.svelte";
  import {
    search,
    quick,
    results,
    status,
    infobox,
    images,
  } from "../fetchData";
  import SearchResult from "./blocks/search-result.svelte";
  import { querystring } from "svelte-spa-router";
  import qs from "qs";
  import Infobox from "./blocks/infobox.svelte";

  let searchParams = qs.parse($querystring);
  let query = searchParams.query;

  search(query);
</script>

<div class="search-results">
  <div class="header">
    <Header {query} />
  </div>
  <div class="results-flexbox">
    <div class="results">
      <div class="results-grid">
        {#if $quick}
          {#each $quick as result}
            <SearchResult {result} />
          {/each}
        {:else}
          <pre>Waiting...</pre>
        {/if}
      </div>
      <div class="image-grid">
        {#if $images}
          {#each $images as image}
            <a href={image.url} target="_blank" rel="noopener noreferrer">
              <img class="image" src={image.thumbnail_src} alt={image.title} />
            </a>
          {/each}
        {/if}
      </div>
      {#if $results && $status == "DONE"}
        <div class="results-grid">
          {#each $results as result}
            {#if result.category != "images"}
              <SearchResult {result} />
            {/if}
          {/each}
        </div>
      {:else}
        <pre>Waiting...</pre>
      {/if}
    </div>

    {#if $infobox != undefined}
      <div class="sidebar">
        {#each $infobox as infobox}
          <Infobox {infobox} />
        {/each}
      </div>
    {/if}
  </div>
</div>

<style>
  .search-results {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
  .header {
    display: flex;
    margin-bottom: 1rem;
    margin-left: 4rem;
  }

  .results-flexbox {
    display: flex;
    flex-flow: row wrap;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    margin-left: 70px;
    width: 25rem;
  }

  .results {
    display: flex;
    flex: 640px;
    flex-direction: column;
    max-width: 570px;
    margin: 0 1rem 1rem 9rem;
  }

  .results-grid {
    display: grid;
    grid-gap: 32px;
    margin-bottom: 16px;
  }
  .image-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 12px;
  }

  .image {
    display: flex;
    flex: 1;
    max-width: 200px;
    max-height: 130px;
    padding: 2px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 1px 0px;
  }
  @media (max-width: 640px) {
    .results-flexbox {
      display: flex;
      flex-flow: row-reverse wrap-reverse;
    }
    .results {
      margin: 1rem 1rem 1rem 1rem;
    }
    .sidebar {
      margin: 0 auto;
    }
    .header {
      margin-left: 1rem;
    }
  }
</style>
